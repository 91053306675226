import { Box } from '@chakra-ui/react'
import clsx from 'clsx'
import React, { memo, VFC } from 'react'
import isEqual from 'react-fast-compare'
import { UploadedFiles } from '../../common/components/FileList'
import { Attachment } from '../../generated'
import classes from './announce.module.css'

interface AnnounceProps {
  type: 'primary' | 'secondary' | 'warning'
  radius?: number
  className?: string
  title: string
  date?: string
  body: React.ReactNode
  attachments?: Array<Attachment>
}

const _Announce: VFC<AnnounceProps> = ({
  type,
  radius,
  className,
  title,
  date,
  body,
  attachments,
}) => {
  const warning = type === 'warning'
  const secondary = type === 'secondary'

  const cn = clsx(
    classes.root,
    className,
    warning ? classes.root__warning : undefined,
    secondary ? classes.root__secondary : undefined
  )

  return (
    <div className={cn} style={radius ? { borderRadius: radius } : {}}>
      <div
        className={clsx(
          classes.head,
          warning && classes.warn_head,
          secondary && classes.secondary_head
        )}
      >
        <div className={clsx(classes.title, warning && `clrRed`)}>{title}</div>
        {date ? <div className={classes.date}>{date}</div> : undefined}
      </div>

      <Box py={2} px={4}>
        <div className={clsx(classes.body, 'preWrap')}>{body}</div>
        {attachments ? (
          <Box mt={2}>
            <UploadedFiles attachments={attachments as Array<Attachment>} />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </div>
  )
}
export const Announce = memo(_Announce, isEqual)
