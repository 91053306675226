/* tslint:disable */
/* eslint-disable */
/**
 * OU-AAO API
 * This is a definition of Osaka University AAD Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: t.shinohara@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EducationCertificateCategory,
    EducationCertificateCategoryFromJSON,
    EducationCertificateCategoryToJSON,
    EducationCertificateType,
    EducationCertificateTypeFromJSON,
    EducationCertificateTypeToJSON,
    Option,
    OptionFromJSON,
    OptionToJSON,
    OptionGroup,
    OptionGroupFromJSON,
    OptionGroupToJSON,
} from '../models';

/**
 * 
 */
export class CommonApi extends runtime.BaseAPI {

    /**
     */
    async getAcceptorRejectReasonsRaw(): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/acceptor-reject-reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     */
    async getAcceptorRejectReasons(): Promise<Array<Option>> {
        const response = await this.getAcceptorRejectReasonsRaw();
        return await response.value();
    }

    /**
     */
    async getCountriesRaw(): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     */
    async getCountries(): Promise<Array<Option>> {
        const response = await this.getCountriesRaw();
        return await response.value();
    }

    /**
     */
    async getCoursesRaw(): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/courses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     */
    async getCourses(): Promise<Array<Option>> {
        const response = await this.getCoursesRaw();
        return await response.value();
    }

    /**
     */
    async getEducationTypesRaw(): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/education-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     */
    async getEducationTypes(): Promise<Array<Option>> {
        const response = await this.getEducationTypesRaw();
        return await response.value();
    }

    /**
     */
    async getEducationalCertificateCategoriesRaw(): Promise<runtime.ApiResponse<Array<EducationCertificateCategory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/education-certificate-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EducationCertificateCategoryFromJSON));
    }

    /**
     */
    async getEducationalCertificateCategories(): Promise<Array<EducationCertificateCategory>> {
        const response = await this.getEducationalCertificateCategoriesRaw();
        return await response.value();
    }

    /**
     */
    async getEducationalCertificateTypesRaw(): Promise<runtime.ApiResponse<Array<EducationCertificateType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/education-certificate-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EducationCertificateTypeFromJSON));
    }

    /**
     */
    async getEducationalCertificateTypes(): Promise<Array<EducationCertificateType>> {
        const response = await this.getEducationalCertificateTypesRaw();
        return await response.value();
    }

    /**
     */
    async getEducationalSystemsRaw(): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/education-systems`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     */
    async getEducationalSystems(): Promise<Array<Option>> {
        const response = await this.getEducationalSystemsRaw();
        return await response.value();
    }

    /**
     */
    async getPartnerSchoolTypesRaw(): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/partner-school-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     */
    async getPartnerSchoolTypes(): Promise<Array<Option>> {
        const response = await this.getPartnerSchoolTypesRaw();
        return await response.value();
    }

    /**
     */
    async getProgramsRaw(): Promise<runtime.ApiResponse<Array<OptionGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/programs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionGroupFromJSON));
    }

    /**
     */
    async getPrograms(): Promise<Array<OptionGroup>> {
        const response = await this.getProgramsRaw();
        return await response.value();
    }

    /**
     */
    async getScholarshipFinancialSupportsRaw(): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scholarship-financial-supports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     */
    async getScholarshipFinancialSupports(): Promise<Array<Option>> {
        const response = await this.getScholarshipFinancialSupportsRaw();
        return await response.value();
    }

    /**
     */
    async getScholarshipStatusesRaw(): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scholarship-statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     */
    async getScholarshipStatuses(): Promise<Array<Option>> {
        const response = await this.getScholarshipStatusesRaw();
        return await response.value();
    }

    /**
     */
    async getScholarshipTypesRaw(): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scholarship-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     */
    async getScholarshipTypes(): Promise<Array<Option>> {
        const response = await this.getScholarshipTypesRaw();
        return await response.value();
    }

    /**
     */
    async getSupplementalMaterialTypesRaw(): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/supplemental-material-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     */
    async getSupplementalMaterialTypes(): Promise<Array<Option>> {
        const response = await this.getSupplementalMaterialTypesRaw();
        return await response.value();
    }

}
