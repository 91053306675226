import { isBefore, parseISO } from 'date-fns/esm'
import React, { FC } from 'react'
import { useLang } from '../../common/i18n/useLang'
import { Announce } from './Announce'

const FIN = parseISO('2023-01-07T23:59:59+09:00')

export function showMaintenance() {
  return isBefore(new Date(), FIN)
}

export const Maintenance: FC<{ radius?: number; className?: string }> = ({ radius, className }) => {
  const { language } = useLang()
  if (!showMaintenance()) {
    return null
  }
  return (
    <Announce
      radius={radius}
      className={className}
      type={'warning'}
      title={
        language === 'ja'
          ? 'AAOゴールデンウィーク休業のお知らせ'
          : 'AAO Office Closing for Golden Week Holidays'
      }
      body={
        language === 'ja' ? (
          <div>
            ゴールデンウィーク休業に伴い、下記の期間AAO事務室は業務をお休みいたします。
            <br />
            5月3日（水) ～ 5月7日（日）※日本時間
            <br />
            期間中はメールへの返信はできませんので、ご了承ください。
            <br />
            また、休業期間中はAAO手続きも停止しますので、早めに申請してください。
            <br />
            ご理解、ご協力の程よろしくお願いいたします。
          </div>
        ) : (
          <div>
            Due to the Golden Week holidays, AAO office will be closed from Wednesday, May 3rd to
            Sunday, May 7th.
            <br />
            We will be unable to respond to your emails/messages during the period. Applicants are
            advised to submit their application early enough so that it can be processed before the
            holidays.
            <br />
            Thank you for your understanding and cooperation.
          </div>
        )
      }
    />
  )
}
