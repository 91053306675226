import {
  Box,
  Heading,
  HStack,
  IconButton,
  Image,
  Link as CLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import clsx from 'clsx'
import React, { memo, VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { FiMenu } from 'react-icons/fi'
import { Link, useLocation } from 'wouter'
import { useEn, useJa, useLang } from '../../common/i18n/useLang'
import { signOut, useApplicant } from '../hooks/useApplicant'
import classes from './header.module.css'

const LoginHeader: VFC = () => {
  const { language } = useLang()
  return (
    <>
      <Box
        as={'header'}
        borderBottom={'1px solid #596e94'}
        bgColor={'#364c97'}
        h={'44px'}
        w={'100%'}
      >
        <HStack
          maxW={'1265px'}
          marginX={'auto'}
          justifyContent={'space-between'}
          align={'flex-end'}
          px={2}
          pt={'8px'}
        >
          <CLink href={'https://www.osaka-u.ac.jp/'}>
            <Text color={'#FFF'} fontSize={'18px'}>
              OSAKA UNIVERSITY
            </Text>
          </CLink>
          <LanguageBox />
        </HStack>
      </Box>
      <Box bgColor={'rgb(240, 241, 242)'} height={'100px'}>
        <HStack w={'768px'} marginX={'auto'} align={'center'} height={'100px'}>
          <Image src={`${import.meta.env.BASE_URL}ou-logo.png`} alt={''} h={'70px'} />
          <Heading
            as={'h1'}
            size={'xl'}
            pl={2}
            color={'#002D72'}
            fontWeight={'400'}
          >
            {language === 'en' ? (
              <>
                <HStack>
                  <Text fontSize={'18px'}>Osaka University</Text>
                  <Text fontSize={'24px'}>Admissions Assistance Desk (AAD)</Text>
                </HStack>
                <Text fontSize={'16px'} mt={2}>
                  Admission support system for prospective international graduate/research students
                </Text>
              </>
            ) : (
              <>
                <HStack>
                  <Text fontSize={'18px'}>大阪大学</Text>
                  <Text fontSize={'24px'}>アドミッション支援デスク（AAD）</Text>
                </HStack>
                <Text fontSize={'16px'} mt={2}>
                  海外からの学生の入学志願申請支援システム
                </Text>
              </>
            )}
          </Heading>
        </HStack>
      </Box>
    </>
  )
}

const LanguageBox: VFC = () => {
  const { language } = useLang()
  return (
    <div className={classes.languageBox}>
      <button
        type="button"
        onClick={useEn}
        className={clsx(
          classes.languageBox_item,
          language === 'en' && classes.languageBox_item__active
        )}
      >
        English
      </button>
      <button
        type="button"
        onClick={useJa}
        className={clsx(
          classes.languageBox_item,
          language === 'ja' && classes.languageBox_item__active
        )}
      >
        日本語
      </button>
    </div>
  )
}

const _Header: VFC = () => {
  const [t] = useTranslation()
  const [location] = useLocation()
  const { state, email } = useApplicant()
  const { language } = useLang()

  if (location === '/') {
    return <LoginHeader />
  }

  return (
    <Box as={'header'} borderBottom={'1px solid #596e94'} bgColor={'#364c97'} w={'100%'}>
      <HStack
        maxW={'1265px'}
        minW={'1000px'}
        marginX={'auto'}
        justifyContent={'space-between'}
        align={'center'}
        px={4}
        py={4}
      >
        <HStack>
          <Image
            src={`${import.meta.env.BASE_URL}ou-logo-w.png`}
            alt="大阪大学 OSAKA UNIVERSITY"
            w={'70px'}
          />
          <Heading
            as={'h1'}
            size={'xl'}
            pl={4}
            fontWeight={400}
            color={'#fff'}
          >
            {language === 'en' ? (
              <>
                <HStack>
                  <Text fontSize={'18px'}>Osaka University</Text>
                  <Text fontSize={'24px'}>Admissions Assistance Desk (AAD)</Text>
                </HStack>
                <Text fontSize={'16px'} mt={2}>
                  Admission support system for prospective international graduate/research students
                </Text>
              </>
            ) : (
              <>
                <HStack>
                  <Text fontSize={'18px'}>大阪大学</Text>
                  <Text fontSize={'24px'}>アドミッション支援デスク（AAD）</Text>
                </HStack>
                <Text fontSize={'16px'} mt={2}>
                  海外からの学生の入学志願申請支援システム
                </Text>
              </>
            )}
          </Heading>
        </HStack>
        <HStack>
          {state === 'signedIn' && <div className={classes.gHeaderMenu_account_email}>{email}</div>}
          <LanguageBox />
          {state === 'signedIn' && (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="menu"
                icon={<FiMenu />}
                variant="link"
                size="lg"
                color="white"
                _active={{ color: 'white' }}
                _focus={{ boxShadow: 'none' }}
              />
              <Box>
                <MenuList borderRadius={'none'} zIndex={1000} fontSize={'12px'}>
                  <Link to={'/applications'}>
                    <MenuItem>{t('申請書')}</MenuItem>
                  </Link>
                  <Link to={'/email-change-request'}>
                    <MenuItem>{t('メールアドレス変更')}</MenuItem>
                  </Link>
                  <Link to={'/password-change'}>
                    <MenuItem>{t('パスワード変更')}</MenuItem>
                  </Link>
                  <MenuItem onClick={signOut}>{t('ログアウト')}</MenuItem>
                </MenuList>
              </Box>
            </Menu>
          )}
        </HStack>
      </HStack>
    </Box>
  )
}

export const Header = memo(_Header)
