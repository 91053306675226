import { FormControl, FormErrorMessage } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import {
  Control,
  FieldError,
  FieldPath,
  RegisterOptions,
  UnpackNestedValue,
  useController,
} from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'
import { useTranslation } from 'react-i18next'
import { ErrorRefCallbackHandler, FormProps, useFormProps } from './useFormProps'

export function FormControlWrapper<T>({
  children,
  ...props
}: FormProps<T> & { children: (props: ErrorRefCallbackHandler) => JSX.Element }) {
  const merged = useFormProps(props)
  return children(merged)
}

export type ControlInputProps<T, V = string> = {
  w?: string
  horizontal?: boolean
  control: Control<T>
  name: FieldPath<T>
  defaultValue?: UnpackNestedValue<FieldPathValue<T, FieldPath<T>>>
  rules?: RegisterOptions<T, FieldPath<T>>
  render: (props: {
    value: V
    onChange: (value: V) => void
    onBlur: () => void
    invalid?: boolean
    error?: FieldError
    name: FieldPath<T>
    isDirty?: boolean
  }) => ReactElement
}

export function ControlInput<T, V = string>({
  w,
  horizontal,
  control,
  name,
  defaultValue,
  rules,
  render,
}: ControlInputProps<T, V>) {
  const [t] = useTranslation()
  const {
    field: { onChange, value, onBlur },
    fieldState: { invalid, error, isDirty },
  } = useController({ control, name, rules, defaultValue })
  return (
    <FormControl
      id={name}
      display={'flex'}
      flexDir={horizontal ? 'row' : 'column'}
      // isRequired={isRequired}
      // isDisabled={isDisabled}
      // dom rendering で warning が出る
      isInvalid={invalid ? true : undefined}
      w={w}
    >
      {render({ onChange, onBlur, value: value as V, invalid, error, name, isDirty })}
      {error?.message && (
        <FormErrorMessage ml={horizontal ? 3 : 0} pt={horizontal ? 3 : 0} fontSize={'12px'}>
          {t(error.message)}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
