import { Center } from '@chakra-ui/react'
import React, { VFC } from 'react'
import { useTitle } from 'react-use'
import { TextLink } from '../../common/components/TextLink'
import { useLang } from '../../common/i18n/useLang'
import { ContainerWithAnnounce } from '../components/ContainerWithAnnounce'
import { Title } from '../components/Title'

const RegistrationRequestComplete: VFC = () => {
  const { t, language } = useLang()
  useTitle(t('AAD 新規会員登録'))
  return (
    <ContainerWithAnnounce>
      <Title text={t('新規会員登録')} />
      <div className={'mt24'}>
        {t('入力されたアドレス宛にメールを送信しました。')}
        <br />
        {/*{t('メール本文に記載されているURLにアクセスしてパスワード登録を行ってください。')}*/}
        {language === 'ja'
          ? 'メール本文に記載されているURLにアクセスしてパスワード登録を行ってください。'
          : 'Please complete your account registration from the URL on the email.'}
      </div>
      <div className={'mt24'}>
        <Center className={'mt8'}>
          <TextLink text={t('トップページへ戻る')} to={'/'} />
        </Center>
      </div>
    </ContainerWithAnnounce>
  )
}

export default RegistrationRequestComplete
