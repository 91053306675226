/* tslint:disable */
/* eslint-disable */
/**
 * OU-AAO API
 * This is a definition of Osaka University AAD Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: t.shinohara@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeEmailRequest
 */
export interface ChangeEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailRequest
     */
    email: string;
}

export function ChangeEmailRequestFromJSON(json: any): ChangeEmailRequest {
    return ChangeEmailRequestFromJSONTyped(json, false);
}

export function ChangeEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeEmailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function ChangeEmailRequestToJSON(value?: ChangeEmailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}


