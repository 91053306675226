import * as Sentry from "@sentry/browser";
import {Dedupe, ExtraErrorData} from "@sentry/integrations";
import {Integrations} from "@sentry/tracing";

export function initSentry() {
  if (!import.meta.env.DEV && import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      environment: (import.meta.env.VITE_SENTRY_ENV ?? 'production') as string,
      dsn: import.meta.env.VITE_SENTRY_DSN as string,
      release: import.meta.env.VITE_SENTRY_RELEASE as string,
      integrations: [new Integrations.BrowserTracing(), new ExtraErrorData(), new Dedupe()],
    })
  }
}