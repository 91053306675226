import { DownloadIcon } from '@chakra-ui/icons'
import { Link, useMediaQuery } from '@chakra-ui/react'
import { Token } from '@chakra-ui/styled-system/dist/types/utils'
import * as CSS from 'csstype'
import React, { FC } from 'react'
import styles from './DownloadLink.module.css'

export const DownloadLink: FC<{
  url?: string
  name?: string
  withIcon?: boolean
  fontSize?: Token<CSS.Property.FontSize | number, 'fontSizes'>
}> = ({ url, name, withIcon, fontSize }) => {
  const [print] = useMediaQuery('print')
  if (!name) return null
  if (print) {
    return (
      <span className={styles.root}>
        {name}
        {withIcon && <DownloadIcon ml={1} />}
      </span>
    )
  } else {
    return (
      <Link
        href={url}
        target={'_blank'}
        rel="noreferrer"
        color={'#319795'}
        wordBreak={'break-all'}
        fontSize={fontSize}
      >
        {name}
        {withIcon && <DownloadIcon ml={1} />}
      </Link>
    )
  }
}
