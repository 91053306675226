import { Text } from '@chakra-ui/react'
import { captureException, captureMessage, Severity, withScope } from '@sentry/browser'
import React, { Component, ErrorInfo, VFC } from 'react'
import { useTitle } from 'react-use'
import { Container } from './Container'

interface ErrorState {
  hasError: boolean
  status?: number
  message?: string
  error?: Error
}

const BrowserError: VFC = () => {
  useTitle('Sorry, your browse is not compatible - Osaka-U AAD System')
  return (
    <Container>
      <h2>
        Sorry, your browser is not supported
        <br />
        このブラウザは現在サポートされていません
      </h2>
      <p>
        The recommended browsers are as follows. Please use <b>the latest version.</b>
        <br />
        以下の推奨ブラウザの<b>最新版</b>をご利用ください。
      </p>
      <ul>
        <li>
          <a target={'_blank'} rel={'noreferrer'} href={'https://www.google.com/chrome/'}>
            Google Chrome
          </a>
        </li>
        <li>
          <a target={'_blank'} rel={'noreferrer'} href={'https://www.microsoft.com/edge'}>
            Microsoft Edge
          </a>
        </li>
        <li>
          <a
            target={'_blank'}
            rel={'noreferrer'}
            href={'https://www.mozilla.org/firefox/browsers/'}
          >
            Firefox
          </a>
        </li>
        <li>
          <a target={'_blank'} rel={'noreferrer'} href={'https://www.apple.com/safari/'}>
            Safari
          </a>
        </li>
      </ul>
      <p>
        For further inquiries, please contact the address below.
        <br />
        ご不明な点がございましたら、下記までお問い合わせください。
      </p>
      <p>
        Admissions Assistance Desk / アドミッション支援デスク (AAD)
        <br />
        <a href={'mailto:contact@ryumon.osaka-u.ac.jp'}>contact@ryumon.osaka-u.ac.jp</a>
      </p>
    </Container>
  )
}

const ErrorBody: VFC<{ error: ErrorState }> = ({ error }) => {
  if (error.status === undefined) {
    return <BrowserError />
  }
  return (
    <Container>
      <Text as={'h2'}>予期せぬエラーが発生しました。</Text>
      {error.message ? (
        <Text as={'p'}>{error.message}</Text>
      ) : error.status ? (
        <Text as={'p'}>status = {error.status}</Text>
      ) : (
        <Text as={'p'}>unknown error</Text>
      )}
      {error.error?.stack && <Text as={'pre'}>{error.error.stack}</Text>}
    </Container>
  )
}

export class ErrorBoundary extends Component<Record<string, unknown>, ErrorState> {
  constructor(props: Record<string, unknown>) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    if (error instanceof Error) {
      return { hasError: true, message: error.message, error }
    } else if (error instanceof Response) {
      return { hasError: true, status: error.status }
    } else {
      return { hasError: true, message: error.toString() }
    }
  }

  componentDidCatch(error: any, errorInfo: ErrorInfo) {
    if (error instanceof Response) {
      error.json().then((json) => {
        if (!import.meta.env.DEV) {
          const severity = error.status >= 500 ? Severity.Error : Severity.Warning
          withScope((scope) => {
            scope.setExtras({
              componentStack: errorInfo.componentStack,
              cookies: document.cookie,
            })
            captureMessage(
              'fetch error:\n\n' +
                JSON.stringify(
                  {
                    url: error.url,
                    status: error.status,
                    body: json,
                  },
                  null,
                  4
                ),
              severity
            )
          })
        }
      })
    } else {
      if (!import.meta.env.DEV) {
        withScope((scope) => {
          scope.setExtras({
            componentStack: errorInfo.componentStack,
            cookies: document.cookie,
          })
          captureException(error)
        })
      }
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBody error={this.state} />
    } else {
      return this.props.children
    }
  }
}
