import { Stack, Text } from '@chakra-ui/react'
import React, { VFC } from 'react'
import { useTitle } from 'react-use'
import { Link } from 'wouter'
import { Container } from '../../common/components/Container'
import { useLang } from '../../common/i18n/useLang'
import { Button } from '../components/Button'
import { Title } from '../components/Title'

export const NotFound: VFC = () => {
  const { t } = useLang()
  useTitle(`AAD Not Found`)
  return (
    <Container innerWidth={'800px'}>
      <Stack spacing={8} mt={8} minHeight={`calc(100vh - 88px)`}>
        <Title text={'Not Found (404)'} />
        <Text>Not Found (404)</Text>
        <Link to={'/applications'}>
          <Button size={'lg'} role={'primary'} text={t('申請画面へ戻る')} />
        </Link>
      </Stack>
    </Container>
  )
}
