import React, { FC } from 'react'
import { Container } from '../../common/components/Container'
import { Maintenance } from './Maintenance'

export const ContainerWithAnnounce: FC = ({ children }) => {
  return (
    <Container innerWidth={'800px'}>
      <Maintenance className={'mb24'} />
      {children}
    </Container>
  )
}
