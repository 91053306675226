import { Button, HStack, Image, Stack } from '@chakra-ui/react'
import { Token } from '@chakra-ui/styled-system/dist/types/utils'
import * as CSS from 'csstype'
import React, { FC, memo, useCallback, useMemo } from 'react'
import { GoArrowDown, GoArrowUp, GoX } from 'react-icons/go'
import { Attachment } from '../../generated'
import { isContentTypeImage } from '../util/isContentTypeImage'
import { DownloadLink } from './DownloadLink'

export interface UploadedFile {
  id: number
  name: string
  url: string
}

const Row: FC<{
  attachment: Attachment
  canEdit?: boolean
  canMove?: boolean
  handleDeleteFile: () => void
  preview?: boolean
  isUpDisabled: boolean
  isDownDisabled: boolean
  index: number
  up: (index: number) => void
  down: (index: number) => void
  fontSize?: Token<CSS.Property.FontSize | number, 'fontSizes'>
}> = ({
  attachment,
  canEdit,
  canMove,
  handleDeleteFile,
  preview,
  isUpDisabled,
  isDownDisabled,
  index,
  up,
  down,
  fontSize,
}) => {
  return (
    <div key={attachment.id}>
      {preview && isContentTypeImage(attachment.contentType) && (
        <Image
          src={attachment.url}
          alt={attachment.name}
          boxSize="160px"
          cursor="pointer"
          objectFit="cover"
        />
      )}
      <Stack direction={`row`} alignItems={'center'} spacing={'4px'}>
        <DownloadLink
          url={attachment.url}
          name={attachment.name}
          withIcon={false}
          fontSize={fontSize}
        />
        {canEdit && (
          <HStack spacing={'2px'}>
            {canMove && (
              <>
                <Button
                  type="button"
                  size={'xs'}
                  onClick={() => up(index)}
                  isDisabled={isUpDisabled ?? false}
                >
                  <GoArrowUp />
                </Button>
                <Button
                  type="button"
                  size={'xs'}
                  onClick={() => down(index)}
                  isDisabled={isDownDisabled ?? false}
                >
                  <GoArrowDown />
                </Button>
              </>
            )}
            <Button
              type="button"
              size={'xs'}
              onClick={handleDeleteFile}
              colorScheme={'red'}
              w={'14px'}
              h={'14px'}
              minW={'14px'}
              mt={1}
              px={0}
            >
              <GoX size={'10px'} />
            </Button>
          </HStack>
        )}
      </Stack>
    </div>
  )
}

interface FileListProps {
  attachments: Array<Attachment> | Attachment | undefined
  canEdit?: boolean
  canMove?: boolean
  className?: string
  preview?: boolean
  onChange?: (attachments: Attachment[]) => void
  onTransfer?: (target: Attachment, direction: 'up' | 'down') => void
  fontSize?: Token<CSS.Property.FontSize | number, 'fontSizes'>
  canTransferByUp?: boolean
  canTransferByDown?: boolean
}

export const _UploadedFiles: FC<FileListProps> = ({
  attachments,
  canEdit,
  canMove,
  onChange,
  className,
  preview,
  fontSize,
  onTransfer,
  canTransferByDown,
  canTransferByUp,
}) => {
  const files = useMemo(() => {
    return attachments ? (Array.isArray(attachments) ? attachments : [attachments]) : []
  }, [attachments])

  const deleteFile = useCallback(
    (index?: number) => {
      if (index === undefined) return
      const newFiles = [...files]
      newFiles.splice(index, 1)
      onChange?.(newFiles)
    },
    [onChange, files]
  )

  const up = useCallback(
    (index) => {
      if (index === 0) {
        if (canTransferByUp) {
          onTransfer?.(files[0], 'up')
        }
        return
      }
      const newFiles = files.slice()
      const target = newFiles[index - 1]
      newFiles[index - 1] = newFiles[index]
      newFiles[index] = target
      onChange?.(newFiles)
    },
    [onChange, onTransfer, canTransferByUp, files]
  )

  const down = useCallback(
    (index) => {
      if (index === files.length - 1) {
        if (canTransferByDown) {
          const target = files[files.length - 1]
          onTransfer?.(target, 'down')
        }
        return
      }
      const newFiles = files.slice()
      const target = newFiles[index + 1]
      newFiles[index + 1] = newFiles[index]
      newFiles[index] = target
      onChange?.(newFiles)
    },
    [onChange, onTransfer, canTransferByDown, files]
  )

  return (
    <Stack spacing={1} className={className}>
      {files.map((u, index) => {
        const isUpDisabled = index === 0
        const isDownDisabled = index === files.length - 1
        return (
          <Row
            key={index}
            attachment={u as Attachment}
            canEdit={canEdit}
            canMove={canMove}
            handleDeleteFile={() => deleteFile(index)}
            preview={preview}
            isUpDisabled={!canTransferByUp && isUpDisabled}
            isDownDisabled={!canTransferByDown && isDownDisabled}
            index={index}
            up={up}
            down={down}
            fontSize={fontSize}
          />
        )
      })}
    </Stack>
  )
}

export const UploadedFiles = memo(_UploadedFiles)
