import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { DevTool } from '@hookform/devtools'
import React, { FC, memo, useCallback, VFC } from 'react'
import {
  Control,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormRegisterReturn,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link as WouterLink } from 'wouter'
import { TextLink } from '../../../common/components/TextLink'
import { FormControlWrapper } from '../../../common/form/FormInput'
import { FormProps } from '../../../common/form/useFormProps'
import { useLang } from '../../../common/i18n/useLang'
import { PasswordInput } from '../../../component/manager/common/atoms/passwordInput/PasswordInput'
import { signIn } from '../../hooks/useApplicant'
import { Announce } from '../Announce'
import { Button } from '../Button'
import { ContainerWithAnnounce } from '../ContainerWithAnnounce'
import { Title } from '../Title'

type LoginInput = {
  email: string
  password: string
}

const _LoginButtons: VFC = () => {
  const [t] = useTranslation()
  return (
    <Stack>
      <Center>
        <Button role={'primary'} size={'md'} type={'submit'} text={t('ログイン')} w={'200px'} />
      </Center>
      <Center>
        <TextLink text={t('パスワードをお忘れの方はこちら')} to={'/password-reset-request'} />
      </Center>
    </Stack>
  )
}
const LoginButtons = memo(_LoginButtons)

const _SignUpLink: VFC = () => {
  const [t] = useTranslation()
  return (
    <Stack>
      <Center>
        {t('新規にアカウントを作成される方は「新規会員登録」ボタンをクリックしてください。')}
      </Center>
      <Center>
        <WouterLink to={'registrations-request'}>
          <Button role={'secondary'} size={'md'} text={t('新規会員登録')} w={'200px'} />
        </WouterLink>
      </Center>
    </Stack>
  )
}
const SignUpLink = memo(_SignUpLink)

// 夏休み時に有効化
const SummerNoteJa1: VFC = () => {
  return (
    <div style={{ border: "4px solid #002D72", padding: "0.5rem 1rem" }}>
      <p style={{ fontWeight: "bold", fontSize: "120%", color: "#002D72"}}>AAD夏季休業のお知らせ</p>
      <p>夏季一斉休業に伴い、次の期間AADは業務をお休みいたします。</p>
      <p>8月10日（土）～ 8月18日（日）※日本時間</p>
      <p style={{ fontWeight: "bold" }}>休業期間中はAADの手続きは停止し、メールへの返信もできませんので、お早めの申請をお願いいたします。</p>
      <p>なお、AADの業務時間は平日の午前9:00～午後5:00（日本時間）ですのでご注意ください。</p>
      <p>ご理解とご協力の程よろしくお願いいたします。</p>
    </div>
  )
}
const SummerNoteJa2: VFC = () => {
  return (
    <div style={{ border: "4px solid #002D72", padding: "0.5rem 1rem" }}>
      <p style={{ fontWeight: "bold", fontSize: "120%", color: "#002D72"}}>日本政府（文部科学省）奨学金留学生　大使館推薦の第一次選考合格者の方へ</p>
      <p>
        AAD申請される際には、
        <Link color="teal.500" href="https://www.osaka-u.ac.jp/ja/international/inbound/files/j14q6y/@@download/file">
          国費外国人留学生大使館推薦第一次選考合格者用
        </Link>
        をよく読んで、申請してください。
      </p>
    </div>
  )
}
const SummerNoteJa: VFC = () => {
  return (
    <div>
      <div><SummerNoteJa2 /></div>
    </div>
  )
}
const SummerNoteEn1: VFC = () => {
  return (
    <div style={{ border: "4px solid #002D72", padding: "0.5rem 1rem" }}>
      <p style={{ fontWeight: "bold", fontSize: "120%", color: "#002D72"}}>AAD Office Closing for Summer Holidays</p>
      <p>Due to the summer holidays of the university, the AAD office will be closed from August 10h to August 18th (Japan Time).</p>
      <p style={{ fontWeight: "bold" }}>It is recommended that applicants submit their applications in advance, as the process will be suspended until after the holidays.</p>
      <p>Additionally, please be aware that our office hours are from 9:00 AM to 5:00 PM on weekdays (Japan Time).</p>
    </div>
  )
}
const SummerNoteEn2: VFC = () => {
  return (
    <div style={{ border: "4px solid #002D72", padding: "0.5rem 1rem" }}>
      <p style={{ fontWeight: "bold", fontSize: "120%", color: "#002D72"}}>For applicants who passed the first screening of the MEXT scholarship embassy recommendation</p>
      <p>
        Please read carefully <Link color="teal.500" href="https://www.osaka-u.ac.jp/en/international/inbound/files/AAD_application_guidelines_202407/@@download/file">How to apply to AAD online system (For those who passed the first screening by the Japanese Embassy/Consulate General)</Link> when you apply to AAD.
      </p>
    </div>
  )
}
const SummerNoteEn: VFC = () => {
  return (
    <div>
      <div><SummerNoteEn2 /></div>
    </div>
  )
}

const DeskNoteJa: VFC = () => {
  return (
    <div>
      （注意）<Link color="teal.500" href="https://rd.iai.osaka-u.ac.jp/#/" isExternal>大阪大学研究者総覧</Link>や<Link color="teal.500" href="https://researchmap.jp/researchers?institution_code=0292*&lang=ja" isExternal>researchmap</Link>では、キーワードなどから教員を検索できます。<br/>
      申請前に志望教員の研究内容をよく確認してください。
    </div>
  )
}
const DeskNoteEn: VFC = () => {
  return (
    <div>
      <Link color="teal.500" href="https://rd.iai.osaka-u.ac.jp/#/" isExternal>The Osaka University Researchers Database</Link> or <Link color="teal.500" href="https://researchmap.jp/researchers?institution_code=0292*&lang=en" isExternal>researchmap</Link> provides information on all faculty members at Osaka University. Before applying, please carefully review the research interests of the faculty member to whom you wish to apply.
    </div>
  )
}

type RowProps = {
  label: string
  children: (props: UseFormRegisterReturn) => JSX.Element
} & FormProps<LoginInput>

const Row: FC<RowProps> = ({ label, children, ...props }) => {
  const [t] = useTranslation()
  return (
    <FormControlWrapper<LoginInput> {...props}>
      {({ id, isInvalid, error, ...inputProps }) => (
        <FormControl
          id={id}
          isRequired={true}
          isInvalid={isInvalid ? true : undefined}
          as={'fieldset'}
          display={'flex'}
          flexDir={'row'}
          alignItems={'baseline'}
        >
          <FormLabel w={'10rem'} fontSize={`14px`} fontWeight={700}>
            {label}
          </FormLabel>
          <Stack>
            {children({ ...inputProps })}
            {error?.message && (
              <FormErrorMessage ml={4} mt={0}>
                {t(error.message)}
              </FormErrorMessage>
            )}
          </Stack>
        </FormControl>
      )}
    </FormControlWrapper>
  )
}

export const LoginForm: FC = () => {
  const { t, language } = useLang()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const methods = useForm<LoginInput>({
    mode: 'onBlur',
  })
  const { control, handleSubmit, setError } = methods

  const href =
    language === `ja`
      ? 'https://www.osaka-u.ac.jp/ja/international/inbound/dragongate'
      : 'https://www.osaka-u.ac.jp/en/international/inbound/dragongate'

  const login: SubmitHandler<LoginInput> = useCallback(
    async (input) => {
      try {
        await signIn(input.email, input.password)
      } catch (error) {
        switch (error.status) {
          case 404:
            setError('email', {
              type: 'manual',
              message: 'アカウントが見つかりません。',
            })
            break
          case 403:
            setError('password', {
              type: 'manual',
              message: 'パスワードが正しくありません。',
            })
            break
        }
      }
    },
    [setError]
  )

  const onError: SubmitErrorHandler<LoginInput> = useCallback((error, event) => {
    console.info('submit error', error, event)
  }, [])

  return (
    <ContainerWithAnnounce>
      <Stack spacing={8}>

        <Announce
          type={'primary'}
          className={'mt10'}
          title={t('申請前に必ず下のウェブサイトをご確認ください。')}
          body={
            <div>
              <div>
                <Link href={href} color="teal.500" isExternal>
                  {t(`アドミッション支援デスク`)} <ExternalLinkIcon mx="2px" />
                </Link>
              </div>
              { language == "ja" ? <DeskNoteJa /> : <DeskNoteEn /> }
            </div>
          }
        />


        <Title text={t('ログイン・新規会員登録')} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(login, onError)} noValidate={true}>
            {import.meta.env.DEV && (
              <DevTool control={control as Control<any>} placement={'top-right'} />
            )}
            <Stack spacing={4}>
              <Row
                control={control}
                path={'email'}
                label={t('メールアドレス')}
                options={{
                  required: '必須入力です。',
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                    message: 'メールアドレスを入力してください。',
                  },
                }}
              >
                {(props) => (
                  <Input
                    {...props}
                    size={'sm'}
                    type="email"
                    w={'24rem'}
                    autoComplete={'email'}
                    maxLength={200}
                  />
                )}
              </Row>

              <Row
                control={control}
                path={'password'}
                label={t('パスワード')}
                skipNormalize={true}
                options={{ required: '必須入力です。' }}
              >
                {(props) => (
                  <PasswordInput
                    size={'sm'}
                    {...props}
                    w={'24rem'}
                    autoComplete={'current-password'}
                  />
                )}
              </Row>

              <LoginButtons />
            </Stack>
          </form>
        </FormProvider>

        <SignUpLink />

        { language == "ja" ? <SummerNoteJa /> : <SummerNoteEn /> }

        <div>
          {language === 'en' ? (
            <p>
              Please access the AAD system from{' '}
              <Link color={'teal.500'} onClick={onOpen}>
                {'the recommended browsers'}
              </Link>
              .
            </p>
          ) : (
            <p>
              ご利用環境については
              <Link color={'teal.500'} onClick={onOpen}>
                {'推奨ブラウザ'}
              </Link>
              をご覧ください。
            </p>
          )}
          <p>
            {t('個人情報の取り扱いに関しては「')}
            <TextLink text={t('個人情報保護方針')} to={'/privacy'} />
            {t('」をご覧ください。')}
          </p>
        </div>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('ご利用環境について')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {language === 'en' ? (
                <p>
                  Please use <b>the latest version</b> of the following browsers.
                </p>
              ) : (
                <p>
                  以下の推奨ブラウザの<b>最新版</b>をご利用ください。
                </p>
              )}
              <UnorderedList p={2}>
                <ListItem>
                  <Link
                    color={'teal.500'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={'https://www.google.com/chrome/'}
                  >
                    Google Chrome
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    color={'teal.500'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={'https://www.microsoft.com/edge'}
                  >
                    Microsoft Edge
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    color={'teal.500'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={'https://www.mozilla.org/firefox/browsers/'}
                  >
                    Firefox
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    color={'teal.500'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={'https://www.apple.com/safari/'}
                  >
                    Safari
                  </Link>{' '}
                  (macOS only)
                </ListItem>
              </UnorderedList>
            </ModalBody>
            <Center mb={4}>
              <Button
                onClick={onClose}
                role={'secondary'}
                size={'sm'}
                text={t('閉じる') as string}
              />
            </Center>
          </ModalContent>
        </Modal>
      </Stack>
    </ContainerWithAnnounce>
  )
}
