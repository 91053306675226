import { Flex, Link, Text } from '@chakra-ui/react'
import React, { VFC } from 'react'

export const Footer: VFC = () => {
  return (
    <Flex
      as={'footer'}
      w={'100%'}
      backgroundColor={'#364c97'}
      borderTop={'1px solid #596e94'}
      justifyContent={'center'}
      align={'center'}
      py={2}
    >
      <Link href={'https://www.osaka-u.ac.jp/'}>
        <Text color={'#FFF'} fontSize={'18px'}>
          大阪大学
        </Text>
      </Link>
    </Flex>
  )
}
