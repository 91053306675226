export enum ColorEnums {
  Primary = '#002D72',
  Destructive = '#B51D1D',
  Secondary = '#002D72',
  Warning = '#ea9327',
  Disable = '#8D949A',
}

export enum HoverColorEnums {
  Primary = '#0d3e9c',
  Destructive = '#e43838',
  Secondary = '#3463bb',
  Warning = '#eca042',
  Disable = '#8D949A',
}
