/* tslint:disable */
/* eslint-disable */
/**
 * OU-AAO API
 * This is a definition of Osaka University AAD Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: t.shinohara@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EducationCertificateType
 */
export interface EducationCertificateType {
    /**
     * 
     * @type {number}
     * @memberof EducationCertificateType
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof EducationCertificateType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EducationCertificateType
     */
    nameJa: string;
}

export function EducationCertificateTypeFromJSON(json: any): EducationCertificateType {
    return EducationCertificateTypeFromJSONTyped(json, false);
}

export function EducationCertificateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EducationCertificateType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'nameJa': json['nameJa'],
    };
}

export function EducationCertificateTypeToJSON(value?: EducationCertificateType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'nameJa': value.nameJa,
    };
}


