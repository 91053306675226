/* tslint:disable */
/* eslint-disable */
/**
 * OU-AAO API
 * This is a definition of Osaka University AAD Application API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: t.shinohara@mmj.ne.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    nameJa: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    urlJa: string;
}

export function LinkFromJSON(json: any): Link {
    return LinkFromJSONTyped(json, false);
}

export function LinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Link {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'nameJa': json['nameJa'],
        'url': json['url'],
        'urlJa': json['urlJa'],
    };
}

export function LinkToJSON(value?: Link | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'nameJa': value.nameJa,
        'url': value.url,
        'urlJa': value.urlJa,
    };
}


